import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBottomSheet.figmaUrl.android} codeUrl={checklists.componentBottomSheet.codeUrl.android} checklists={checklists.componentBottomSheet.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Bottomsheets are bottom-anchored overlays that that allow the user to easily return to the previous screen. They are meant for temporary focused tasks.
Sheets are the mobile equivalent of the modal or overlay on web. Bottomsheet also known as Drawer , Panel, Tray.`}</p>
    <p>{`Legion has some variant of Bottomsheet and can set :`}</p>
    <ol>
      <li parentName="ol">{`Title with handler or without handler`}</li>
      <li parentName="ol">{`Description with handler or without Handler`}</li>
      <li parentName="ol">{`Button with handler or without handler`}</li>
      <li parentName="ol">{`Icon left with handler or without handler`}</li>
      <li parentName="ol">{`Hide or show close button`}</li>
      <li parentName="ol">{`Hide header`}</li>
    </ol>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/android/Bottom_Sheet.gif",
            "alt": "bottomsheet android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h2>{`Usage`}</h2>
    <p>{`To implement bottomsheet, you can follow the steps below.`}</p>
    <h3>{`Basic implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.Handler
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Title with handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.DefaultWithHandler(title = "title")
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Description with handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.DefaultWithHandler(description = "description")
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Button with handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.DefaultWithHandler(title = "title")
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
        addButton(LgnPrimaryFitButton(requireContext())) {
            text = "Dismiss"
            setOnClickListener { dismiss() }
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Icon with handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.DefaultWithHandler(icon = ContextCompat.getDrawable(requireContext(),android.R.drawable.ic_menu_info_details))
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Show close with handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.DefaultWithHandler(showClose = true)
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Title without handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.Default(title = "title")
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Description without handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.Default(description = "description")
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Button without handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.Default(title = "title")
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
        addButton(LgnPrimaryFitButton(requireContext())) {
            text = "Dismiss"
            setOnClickListener { dismiss() }
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Icon without handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.Default(icon = ContextCompat.getDrawable(requireContext(),android.R.drawable.ic_menu_info_details))
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}



...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h3>{`Hide close without handler implement`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
btnAction.setOnClickListener {
    LgnBottomSheet.setup(requireContext()) {
        ...
        orientation = LgnBottomSheet.Orientation.VERTICAL
        header = LgnBottomSheet.HeaderView.Default(showClose = false)
        setContent<LayoutBottomsheetBinding> {
            ...
        }
        ...
    }.showNow(childFragmentManager, "bottomsheet")
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <h2>{`Themes`}</h2>
    <p>{`There are 4 themes available on the bottom sheet Legion :`}</p>
    <ol>
      <li parentName="ol">{`Legion Default`}</li>
      <li parentName="ol">{`Agree Culture`}</li>
      <li parentName="ol">{`Agree Livestock`}</li>
      <li parentName="ol">{`Agree Fisheries`}</li>
    </ol>
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set view type for header`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Orientation Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`orientation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set footer button orientation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Content View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setContent<T : ViewBinding>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Configure content views, can modify your views at provided lambda`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Button on Footer Bottom Sheet with Builder Pattern, and maximum users can add button. is 3 button, and when more than 3 will throw `}{`[IndexOutOfBoundsException]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dismiss`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dismiss`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dismiss Bottom Sheet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Dismiss Listener`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setBottomSheetListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`receive listener on bottom sheet is dismiss`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      